import VueRouter from 'vue-router'

import '../libs/instance-properties'
import '../js/navigation'

import * as Sentry from '@sentry/vue'

import Turbolinks from 'turbolinks'
import TurbolinksAdapter from '../plugins/turbolinks'

import Vue from 'vue'
import VueCompositionAPI, { createApp, provide } from '@vue/composition-api'
import { createPinia, PiniaVuePlugin } from 'pinia'

import moment from 'moment'

import vuetify from '../plugins/vuetify'
import store from '../store'
import i18n from '../i18n/i18n'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '../plugins/apollo-client'

import * as pageComponents from '../modules/modules'

import * as coreComponents from '../modules/core'
import * as formComponents from '../modules/core/forms'
import * as AppRoutes from '../modules/routes'

import infiniteScroll from 'vue-infinite-scroll'
import SessionHeartBeat from './session-heart-beat'
import filters from '../modules/core/filters'

const { sentryDsn, openTelemetryEnabled, sentryCurrentEnv } =
  window.DenteoGlobals

const sessionHeartBeat = new SessionHeartBeat()
sessionHeartBeat.start()

try {
  if (sentryDsn) {
    const sentryConfig = {
      Vue,
      dsn: sentryDsn,
      integrations: (integrations) => {
        return [
          integrations.filter((integration) => {
            return integration.name !== 'Dedupe'
          }),
          openTelemetryEnabled ? new Sentry.BrowserTracing() : false,
        ]
          .filter(Boolean)
          .flat()
      },
      release: 'e075bf361',
      debug: import.meta.env.DEV,
      environment: sentryCurrentEnv,
      ignoreErrors: [
        'Request failed with status code 401', // Axios Error when the server responds with 401 (JSON endpoints)
        // TODO: [BREAKING] ApolloError's thrown by Apollo Client no longer prefix error messages with GraphQL error: or Network error:. To differentiate between GraphQL/network errors, refer to ApolloError's public graphQLErrors and networkError properties.
        'GraphQL Error: Unauthorized', // raised by GraphQL (currently only Axios)
        'GraphQL error: You need to authenticate to perform this action', // raised by Apollo Client
        'CanceledError: canceled', // raised by AbortController.abort
      ],
    }

    if (openTelemetryEnabled) {
      sentryConfig.tracesSampleRate = 0.1
    }

    Sentry.init(sentryConfig)
  }
} catch (error) {
  console.warn(error)
}

Object.entries({
  ...formComponents,
  ...coreComponents,
  ...pageComponents,
}).forEach(([key, value]) => {
  Vue.component(key, value)
})

Vue.use(VueCompositionAPI)
Vue.use(infiniteScroll)
Vue.use(TurbolinksAdapter)
Vue.use(VueRouter)

Vue.mixin({
  methods: {
    $l(date, format = 'DD.MM.YYYY') {
      return moment(date).format(format)
    },
  },
})

/* Polyfill from https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

Turbolinks.start()

// TODO: not sure if DenteoGlobals can change through a turbolinks request
const { locale } = window.DenteoGlobals

const appSettings = {
  locale,
}

document.addEventListener('turbolinks:load', () => {
  createFilters(filters, appSettings)
  moment.locale(locale, {
    longDateFormat: {
      L: 'DD.MM.YYYY',
      LT: 'HH:mm',
    },
  })
  i18n.i18next.changeLanguage(locale)

  if (import.meta.env.DEV) {
    i18n.i18next.on(
      'missingKey',
      (languages, namespace, key, fallbackValue) => {
        console.log('language_keys', {
          languages,
          namespace,
          key,
          fallbackValue,
        })
      }
    )
  }

  document
    .querySelectorAll('.vue-app:not([data-turbolinks-permanent])')
    .forEach(createVueApp)
})

const router = new VueRouter({
  mode: 'hash',
  routes: Object.values({ ...AppRoutes }),
  linkActiveClass: 'd-layout__sidebar-link--active',
  linkExactActiveClass: 'd-layout__sidebar-link--exact-active',
})

const createVueApp = (el) => {
  const app = createApp({
    setup() {
      provide(appSettings)
      provide(DefaultApolloClient, apolloClient)
    },
    vuetify,
    store,
    i18n,
    router,
    strict: true,
    pinia: createPinia(),
  })
  app.use(PiniaVuePlugin)
  app.mount(el)
}

function createFilters(filters, settings) {
  Object.entries(filters).forEach(([filterName, filterFactory]) => {
    Vue.filter(filterName, filterFactory(settings))
  })
}

document.addEventListener('DOMContentLoaded', () =>
  document
    .querySelectorAll('.vue-app[data-turbolinks-permanent]')
    .forEach(createVueApp)
)
