<template>
  <d-form
    :form-data="formData"
    :validation="validationsOfChecked"
    :errors="errors"
    @valid-submit="submit"
  >
    <d-modal>
      <d-modal-title :title="title" />

      <d-modal-content>
        <v-row v-for="(attribute, name) in attributes" :key="name" no-gutters>
          <v-col cols="4">
            <d-input-checkbox
              v-model="checked[name]"
              :label="attribute.i18nKey"
            />
          </v-col>

          <v-col cols="8">
            <slot :name="name" v-bind="attrs(name)" />
          </v-col>
        </v-row>
      </d-modal-content>

      <d-modal-actions>
        <d-btn role="secondary" label="cancel" @click="$emit('cancel')" />
        <d-btn
          type="submit"
          :disabled="!anyChecked"
          label="save"
          role="primary"
        />
      </d-modal-actions>
    </d-modal>
  </d-form>
</template>

<script>
export default {
  props: {
    attributes: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    validation: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      checked: {},
    }
  },
  computed: {
    formData() {
      return {
        ...Object.keys(this.attributes).reduce(
          (o, key) => ({ ...o, [key]: this.attributes[key].value }),
          {}
        ),
      }
    },
    anyChecked() {
      return Object.values(this.checked).some((checked) => checked)
    },
    validationsOfChecked() {
      return this.setForChecked({}, (key) => this.validation[key] || {})
    },
  },
  methods: {
    setForChecked(object, valueForKey) {
      Object.entries(this.checked).forEach(([name, checked]) => {
        if (checked) {
          object[name] = valueForKey(name)
        }
      })
      return object
    },
    attrs(name) {
      return {
        placeholder: this.attributes[name].value
          ? ''
          : this.$t('variousValues'),
        disabled: !this.checked[name],
        label: '',
      }
    },
    submit(form) {
      const values = this.setForChecked({}, (key) => form[key])
      this.$emit('save', values)
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
