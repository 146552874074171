<template>
  <d-modal>
    <d-modal-title
      :title="title"
      v-bind="{ 'hide-close-button': !showCloseButton ? true : false }"
    />
    <d-modal-content>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span style="font-size: 16px" v-html="description" />
    </d-modal-content>
    <d-modal-checkbox
      v-if="showRecurrenceCheckbox"
      :label="$t('duplicateWarning.checkbox_label')"
      @check="handleCheckbox"
    />
    <d-modal-actions>
      <d-btn
        v-if="showCancel"
        role="secondary"
        :label="i18nKeyLabelCancel"
        @click="$emit('cancel')"
      />
      <d-btn type="submit" :label="i18nKeyLabelSubmit" @click="close" />
    </d-modal-actions>
  </d-modal>
</template>

<script lang="ts">
import { setDialogRecurrence } from '@/services/local-setting';
import { ref, onMounted } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  uiContext: {
    key: "uiContext",
    required: false,
    type: String
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  description: {
    key: "description",
    required: true,
    type: String
  },
  i18nKeyLabelSubmit: {
    key: "i18nKeyLabelSubmit",
    required: false,
    type: String,
    default: 'common:submit'
  },
  i18nKeyLabelCancel: {
    key: "i18nKeyLabelCancel",
    required: false,
    type: String,
    default: 'common:cancel'
  },
  showCloseButton: {
    key: "showCloseButton",
    required: false,
    type: Boolean,
    default: true
  },
  showCancel: {
    key: "showCancel",
    required: false,
    type: Boolean,
    default: false
  },
  showRecurrenceCheckbox: {
    key: "showRecurrenceCheckbox",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const checkBoxState = ref(false);
  const props = __props;
  onMounted(() => {
    setDialogRecurrence(props.uiContext, true);
    document.addEventListener('keyup', handleEnterKey);
  });
  const emit = __ctx.emit;
  const close = () => {
    if (checkBoxState.value) {
      setDialogRecurrence(props.uiContext, false);
    }
    emit('close');
  };
  const handleEnterKey = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      close();
    }
  };
  const handleCheckbox = (state: boolean) => {
    checkBoxState.value = state;
  };
  return {
    close,
    handleCheckbox
  };
};
export default __sfc_main;
</script>
