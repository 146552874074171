<template>
  <d-modal>
    <d-modal-title :hide-close-button="inList">
      <h5 class="text-h5">
        {{ dialogTitle || $t('news-dialog:dialogTitle') }}
      </h5>
    </d-modal-title>
    <d-modal-content spacing="px-0">
      <a :href="url" target="_blank" rel="noopener" class="block">
        <img :src="imageSrc" class="news-image" />
      </a>
      <div class="news-content pb-0 pt-12">
        <div v-if="date" class="text--secondary text-center">
          {{ formatDate(date) }}
        </div>
        <h5 class="text-h4 text-center mb-6">{{ title }}</h5>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="news-text" v-html="description" />
      </div>
    </d-modal-content>
    <d-modal-actions>
      <div
        v-if="url"
        class="d-flex justify-center news-buttons"
        style="width: 100%"
      >
        <d-btn
          large
          class="news-button"
          type="submit"
          label="news-dialog:more"
          :href="url"
          target="_blank"
          :role="inList ? 'text' : 'primary'"
          @click="emit('cancel')"
        />
      </div>
    </d-modal-actions>
  </d-modal>
</template>

<script lang="ts">
import { formatDate } from '../../../libs/dateTime';
// TODO: show beta label
const __sfc_main = {};
__sfc_main.props = {
  dialogTitle: {
    key: "dialogTitle",
    required: false,
    type: String,
    default: null
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  description: {
    key: "description",
    required: true,
    type: String
  },
  date: {
    key: "date",
    required: false,
    type: String,
    default: null
  },
  imageSrc: {
    key: "imageSrc",
    required: true,
    type: String
  },
  url: {
    key: "url",
    required: true,
    type: String
  },
  beta: {
    key: "beta",
    required: false,
    type: Boolean,
    default: false
  },
  inList: {
    key: "inList",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    formatDate,
    emit
  };
};
export default __sfc_main;
</script>

<style scoped>
.news-image {
  width: 100%;
  height: auto;
}
.news-content {
  max-width: 416px;
  margin: 0 auto;
}
.news-text {
  font-size: 16px;
  line-height: 22px;
}
.news-buttons {
  gap: 8px;
}
.news-button {
  width: 50%;
}
</style>
