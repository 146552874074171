
<v-card-title class="pa-6" :class="titleClass">
  <div>
    <slot>
      <h5 class="text-h5">{{ $t(title) }}</h5>
    </slot>
  </div>
  <v-spacer />
  <slot name="menu-actions" />
  <v-btn v-if="!hideCloseButton" class="mr-n2" icon @click="handleClose">
    <v-icon>mdi-close</v-icon>
  </v-btn>
</v-card-title>
