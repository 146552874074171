
<d-modal>
  <d-modal-title
    :title="title"
    v-bind="{ 'hide-close-button': !showCloseButton ? true : false }"
  />
  <d-modal-content>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span style="font-size: 16px" v-html="description" />
  </d-modal-content>
  <d-modal-checkbox
    v-if="showRecurrenceCheckbox"
    :label="$t('duplicateWarning.checkbox_label')"
    @check="handleCheckbox"
  />
  <d-modal-actions>
    <d-btn
      v-if="showCancel"
      role="secondary"
      :label="i18nKeyLabelCancel"
      @click="$emit('cancel')"
    />
    <d-btn type="submit" :label="i18nKeyLabelSubmit" @click="close" />
  </d-modal-actions>
</d-modal>
